/**
 * @type {Map<string, number>}
 */
const usedIds = new Map();

/**
 * Generate a unique ID.
 *
 * @param {string} [prefix]
 * @returns {string}
 */
export function uniqueId(prefix = '') {
  let key = prefix ? prefix + '-' : prefix;
  let increment = usedIds.get(key) ?? 0;
  usedIds.set(key, ++increment);
  return key + increment;
}

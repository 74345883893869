import {toggleAriaCurrent} from '@/utilities/accessibility.js';
import {LocalizeMixin } from '@/utilities/mixins/LocalizeMixin.js';

const template = document.createElement('template');

template.innerHTML = /*html*/`
  <style>
    :host {
      display: block;
    }
  </style>
  <slot></slot>
`;

export class CarouselItem extends LocalizeMixin(HTMLElement) {
  static get observedAttributes() {
    return ['selected'];
  }

  static get translations() {
    return {
      de: {
        role: 'Slide'
      },
      en: {
        role: 'Slide'
      }
    };
  }

  constructor() {
    super();

    const shadowRoot = this.attachShadow({mode: 'open'});
    shadowRoot.append(template.content.cloneNode(true));

    this.#addAriaRoles();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'selected') {
      this.#update({
        selected: newValue !== null
      });
    }
  }

  get selected() {
    return this.getAttribute('selected');
  }

  set selected(value) {
    if (value) {
      this.setAttribute('selected', '');
    } else {
      this.removeAttribute('selected');
    }
  }

  get summary() {
    const summary = this.getAttribute('data-summary')
      ?? this.getAttribute('aria-label')
      ?? this.getAttribute('aria-description');

    if (summary) {
      return summary;
    }

    const id = this.getAttribute('aria-labelledby')
      ?? this.getAttribute('aria-describedby');

    if (id) {
      return document.getElementById(id)?.textContent?.trim();
    }

    const heading = this.querySelector('h1, h2, h3, h4, h5, h6, figcaption');

    if (heading) {
      return heading.textContent?.trim();
    }

    return null;
  }

  #addAriaRoles() {
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'group');
    }

    if (!this.hasAttribute('aria-roledescription')) {
      this.setAttribute('aria-roledescription', this.translate('role'));
    }
  }

  #update({selected}) {
    toggleAriaCurrent(this, selected);
  }
}

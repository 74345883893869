/**
 * Determine whether the Animation API is supported by the current client.
 *
 * @returns {boolean}
 */
export function supportsAnimation() {
  return Object.hasOwnProperty.call(Element.prototype, 'animate');
}

/**
 * Determine whether the current browser grants access to the session storage.
 *
 * NOTE: This utility function can be useful for situation where the user has
 * disabled cookies entirely. In those instances accessing the local or session
 * storage would throw an exception.
 *
 * @param {string} [key] The key of the test value to store.
 * @returns {boolean}
 */
export function supportsSessionStorage(key = '__session_storage_key') {
  try {
    window.sessionStorage.setItem(key, null);
    window.sessionStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
}

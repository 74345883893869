import PhotoSwipeLightbox from 'photoswipe/lightbox';

import {
  range
} from '@/utilities/array.js';

import {
  toggleAriaCurrent
} from '@/utilities/accessibility.js';

import {
  LocalizeMixin
} from '@/utilities/mixins/LocalizeMixin.js';

export class Gallery extends LocalizeMixin(HTMLElement) {
  static get translations() {
    return {
      de: {
        slide: 'Slide {index}',
        close: 'Schließen',
        zoom: 'Vergrößern',
        prev: 'Vorheriges Bild',
        next: 'Nächstes Bild',
        error: 'Das Bild konnte nicht geladen werden'
      },
      en: {
        slide: 'Slide {index}',
        close: 'Close',
        zoom: 'Zoom',
        prev: 'Previous Image',
        next: 'Next Image',
        error: 'The image cannot be loaded'
      }
    };
  }

  #lightbox = null;

  #lightboxPaginationButtons = null;
  #lightboxZoomIndicator = null;

  connectedCallback() {
    const images = this.querySelectorAll('a[data-pswp-width], a[data-pswp-height]');

    this.#lightbox = new PhotoSwipeLightbox({
      pswpModule: () => import('photoswipe'),
      gallery: this,
      children: images,
      padding: {
        top: 64,
        right: 96,
        bottom: 64,
        left: 96,
      },
      opacity: 1,
      initialZoomLevel: 'fit',
      secondaryZoomLevel: 1,
      maxZoomLevel: 2,
      closeTitle: this.translate('close'),
      zoomTitle: this.translate('zoom'),
      arrowPrevTitle: this.translate('prev'),
      arrowNextTitle: this.translate('next'),
      errorMsg: this.translate('error')
    });

    this.#lightbox.on('uiRegister', this.#handleUiRegister);
    this.#lightbox.on('change', this.#handleSlideChange);
    this.#lightbox.on('zoomPanUpdate', this.#handleZoomUpdate);

    this.#lightbox.init();
  }

  disconnectedCallback() {
    this.#lightbox.destroy();
  }

  get currentItem() {
    return this.#lightbox?.pswp?.currSlide?.data?.element;
  }

  get current() {
    return this.#lightbox?.pswp?.currSlide?.index;
  }

  get zoomLevel() {
    return this.#lightbox?.pswp?.currSlide?.currZoomLevel;
  }

  openLightbox(index = 0) {
    this.#lightbox?.loadAndOpen(index);
  }

  #createPaginationButtons(element, pswp) {
    element.replaceChildren(
      ...range(0, pswp.getNumItems()).map((index) => {
        const button = document.createElement('button');

        button.setAttribute('class', 'pswp__pagination-button');
        button.setAttribute('aria-label', this.translate('slide', {index: index + 1}));
        button.setAttribute('data-index', index);

        button.addEventListener('click', () => {
          pswp.goTo(parseInt(button.getAttribute('data-index'), 10));
        });

        return button;
      })
    );
  }

  #updatePaginationButtons(element, pswp) {
    Array.from(element.children).forEach((button, index) => {
      toggleAriaCurrent(button, index === pswp.currSlide?.index);
    });
  }

  #createZoomIndicator(element, pswp) {
    const zoom = pswp.currSlide?.currZoomLevel ?? 1;

    element.setAttribute('value', zoom);
    element.innerText = `${Math.round(zoom * 100)}%`;
  }

  #updateZoomIndicator(element, pswp) {
    const zoom = pswp.currSlide.currZoomLevel ?? 1;

    element.setAttribute('value', zoom);
    element.innerText = `${Math.round(zoom * 100)}%`;
  }

  #handleSlideChange = () => {
    if (this.#lightboxPaginationButtons) {
      this.#updatePaginationButtons(
        this.#lightboxPaginationButtons,
        this.#lightbox.pswp
      );
    }
  };

  #handleZoomUpdate = () => {
    if (this.#lightboxZoomIndicator) {
      this.#updateZoomIndicator(
        this.#lightboxZoomIndicator,
        this.#lightbox.pswp
      );
    }
  };

  #handleUiRegister = () => {
    this.#lightbox.pswp.ui.registerElement({
      name: 'pagination-buttons',
      tagName: 'div',
      appendTo: 'wrapper',
      onInit: (element, pswp) => {
        this.#createPaginationButtons(element, pswp);
        this.#lightboxPaginationButtons = element;
      }
    });

    this.#lightbox.pswp.ui.registerElement({
      name: 'zoom-level',
      tagName: 'data',
      order: 9,
      onInit: (element, pswp) => {
        this.#createZoomIndicator(element, pswp);
        this.#lightboxZoomIndicator = element;
      }
    });
  };
}

import emblaCarousel from 'embla-carousel';
import emblaAutoplay from 'embla-carousel-autoplay';

import {
  toggleAriaCurrent,
  toggleAriaDisabled,
  toggleAriaLive
} from '@/utilities/accessibility.js';

import {
  prefersReducedMotion
} from '@/utilities/animation.js';

import {
  LocalizeMixin
} from '@/utilities/mixins/LocalizeMixin.js';

const template = document.createElement('template');

template.innerHTML = /*html*/`
  <style>
    *,
    &::before,
    &::after {
      box-sizing: border-box;
    }

    button {
      appearance: none;
      display: inline-flex;
      vertical-align: middle;
      padding: 0;
      border: 0;
      margin: 0;
      color: var(--carousel-theme-color);
      background-color: transparent;
      cursor: pointer;
    }

    button:focus {
      outline: var(--carousel-outline-size) var(--carousel-outline-style) var(--carousel-outline-color);
      outline-offset: var(--carousel-outline-offset, var(--carousel-outline-size));
    }

    button:focus-visible {
      outline: var(--carousel-outline-size) var(--carousel-outline-style) var(--carousel-outline-color);
      outline-offset: var(--carousel-outline-offset, var(--carousel-outline-size));
    }

    button:not(:focus-visible){
      outline: none;
    }

    svg {
      display: block;
      width: 1em;
      height: 1em;
      fill: none;
      stroke: currentcolor;
    }

    :host {
      --carousel-theme-color: blue;
      --carousel-outline-size: 3px;
      --carousel-outline-style: solid;
      --carousel-outline-color: var(--carousel-theme-color);
    }

    :host::part(viewport) {
      overflow: hidden;
    }

    :host::part(reel) {
      display: flex;
      gap: var(--carousel-item-gutter, 0);
      touch-action: pan-y;
      will-change: transform;
    }

    :host::part(controls) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--carousel-control-gutter, 1rem);
      color: var(--color-theme-color);
    }

    :host([idle])::part(controls) {
      display: none !important;
    }

    :host::part(button) {
      position: relative;
      z-index: var(--carousel-layer-control, 1);
      width: 1em;
      height: 1em;
    }

    :host::part(button-prev),
    :host::part(button-next),
    :host::part(button-stop),
    :host::part(button-play) {
      font-size: var(--carousel-button-size, 1.5rem);
    }

    :host::part(button-prev disabled),
    :host::part(button-next disabled) {
      opacity: 0.5;
    }

    :host::part(button-play disabled),
    :host::part(button-stop disabled) {
      display: none;
    }

    :host::part(pagination) {
      display: flex;
      align-items: center;
      gap: var(--carousel-pagination-gutter, 1rem);
    }

    :host::part(pagination-button) {
      border: 0.125em solid var(--carousel-theme-color);
      border-radius: 50%;
      font-size: var(--carousel-pagination-size, 1rem);
    }

    :host::part(pagination-button current) {
      background-color: var(--carousel-theme-color);
    }

    ::slotted(*) {
      flex: 0 0 var(--carousel-item-size, 100%);
      min-width: 0;
    }

    .visually-hidden {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      padding: 0;
      border: 0;
      margin: 0;
      clip: rect(0 0 0 0);
      white-space: nowrap;
    }
  </style>

  <div part="container">
    <div part="controls" role="group" aria-labelledby="controls-heading">
      <h2 id="controls-heading" class="visually-hidden" data-localize="controls">
        Slide Controls
      </h2>

      <button part="button button-stop" type="button">
        <svg part="icon icon-stop" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" focusable="false">
          <g fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
            <path d="M7,4.5v15a1.5,1.5,0,0,0,3,0V4.5a1.5,1.5,0,0,0-3,0Z"/>
            <path d="M14,4.5v15a1.5,1.5,0,0,0,3,0V4.5a1.5,1.5,0,0,0-3,0Z"/>
          </g>
        </svg>
        <span class="visually-hidden" data-localize="stop">
          Pause Autoplay
        </span>
      </button>

      <button part="button button-play" type="button">
        <svg part="icon icon-play" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" focusable="false" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
          <g fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
            <path d="M17.24474,12.087l-6.911-6.67593C9.77868,4.87483,9,5.40151,9,6.31317V18.8827c0,.88224.73462,1.41531,1.29355.93866l6.911-5.8936A1.3412,1.3412,0,0,0,17.24474,12.087Z"/>
          </g>
        </svg>
        <span class="visually-hidden" data-localize="play">
          Resume Autoplay
        </span>
      </button>

      <div part="pagination"></div>

      <button part="button button-prev" type="button" aria-controls="items">
        <svg part="icon icon-prev" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" focusable="false">
          <path d="M 16,2 l -11,10 l 11,10" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="visually-hidden" data-localize="prev">
          Previous Slide
        </span>
      </button>

      <button part="button button-next" type="button" aria-controls="items">
        <svg part="icon icon-next" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" focusable="false">
          <path d="M 8,2 l 11,10 l -11,10" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="visually-hidden" data-localize="next">
          Next Slide
        </span>
      </button>
    </div>
    <div part="viewport">
      <slot id="items" part="reel"></div>
    </div>
  </div>
`;

/**
 * @todo improve label of pagination buttons
 */
export class Carousel extends LocalizeMixin(HTMLElement) {
  static get observedAttributes() {
    return [
      'dir',
      'align',
      'autoplay',
      'loop',
      ...super.observedAttributes
    ];
  }

  static get translations() {
    return {
      de: {
        role: 'Karussell',
        controls: 'Bedienelemente',
        pagination: 'Slide {slide} von {total}',
        prev: 'Vorherige Slide',
        next: 'Nächste Slide',
        stop: 'Automatische Wiedergabe anhalten',
        play: 'Automatische Wiedergabe fortsetzen',
      },
      en: {
        role: 'Carousel',
        controls: 'Slide Controls',
        pagination: 'Slide {slide} of {total}',
        prev: 'Previous Slide',
        next: 'Next Slide',
        stop: 'Stop Autoplay',
        play: 'Resume Autoplay'
      }
    };
  }

  #viewport = null;
  #reel = null;

  #emblaUpdateRequested = false;
  #embla = null;

  #dir = 'ltr';
  #align = 'start';
  #autoplay = false;
  #loop = false;

  constructor() {
    super();

    const shadowRoot = this.attachShadow({mode: 'open'});
    shadowRoot.append(template.content.cloneNode(true));

    this.#viewport = shadowRoot.querySelector('[part~="viewport"]');
    this.#reel = shadowRoot.querySelector('[part~="reel"]');

    this.#addAriaRoles();
  }

  connectedCallback() {
    super.connectedCallback();

    this.#embla = emblaCarousel(this, {
      container: this.#reel,
      slides: this.items,
      align: this.align,
      loop: this.loop,
      duration: prefersReducedMotion() ? 10 : 25,
      slidesToScroll: 1,
      startIndex: 0,
      watchSlides: false,
      inViewThreshold: 0.5
    }, [
      ...this.autoplay ? [emblaAutoplay({delay: this.autoplay})] : [],
    ]);

    this.#embla.on('reInit', this.#handleEmblaReInit);
    this.#embla.on('select', this.#handleEmblaSelect);
    this.#embla.on('slidesInView', this.#handleEmblaSlidesInView);
    this.#embla.on('autoplay:stop', this.#handleEmblaAutoplayStop);
    this.#embla.on('autoplay:play', this.#handleEmblaAutoplayResume);

    this.shadowRoot.addEventListener('click', this.#handleClick);
    this.shadowRoot.addEventListener('keydown', this.#handleKeydown);
    this.shadowRoot.addEventListener('focusin', this.#handleFocusIn);
    this.shadowRoot.addEventListener('focusout', this.#handleFocusOut);
    this.shadowRoot.addEventListener('slotchange', this.#handleSlotChange);

    this.#updateAutoplayButtons({
      autoplay: this.autoplay,
      playing: Boolean(this.autoplay),
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.shadowRoot.removeEventListener('slotchange', this.#handleSlotChange);
    this.shadowRoot.removeEventListener('focusout', this.#handleFocusOut);
    this.shadowRoot.removeEventListener('focusin', this.#handleFocusOut);
    this.shadowRoot.removeEventListener('keydown', this.#handleKeydown);
    this.shadowRoot.removeEventListener('click', this.#handleClick);

    this.#embla.off('autoplay:stop', this.#handleEmblaAutoplayStop);
    this.#embla.off('autoplay:play', this.#handleEmblaAutoplayResume);
    this.#embla.off('slidesInView', this.#handleEmblaSlidesInView);
    this.#embla.off('select', this.#handleEmblaSelect);
    this.#embla.off('reInit', this.#handleEmblaReInit);

    this.#embla.destroy();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    super.attributeChangedCallback(name, oldValue, newValue);

    if (oldValue !== newValue) {
      if (name === 'dir') {
        this.#dir = newValue;
        this.#scheduleEmblaUpdate();
      } else if (name === 'align') {
        this.#align = newValue;
        this.#scheduleEmblaUpdate();
      } else if (name === 'autoplay') {
        this.#autoplay = newValue !== null ? parseInt(newValue || 5000, 10) : false;
        this.#scheduleEmblaUpdate();
      } else if (name === 'loop') {
        this.#loop = newValue !== null;
        this.#scheduleEmblaUpdate();
      }
    }
  }

  get selectedItem() {
    return this.items.find((element) => (
      element.hasAttribute('selected')
    ));
  }

  get selected() {
    return this.items.findIndex((element) => (
      element.hasAttribute('selected')
    ));
  }

  get items() {
    return this.#reel.assignedElements();
  }

  get align() {
    return this.#align;
  }

  set align(value) {
    if (value) {
      this.setAttribute('align', value);
    } else {
      this.removeAttribute('align');
    }
  }

  get dir() {
    return this.#dir;
  }

  set dir(value) {
    if (value) {
      this.setAttribute('dir', value);
    } else {
      this.removeAttribute('dir');
    }
  }

  get autoplay() {
    return this.#autoplay;
  }

  set autoplay(value) {
    if (value !== null) {
      this.setAttribute('autoplay', value);
    } else {
      this.removeAttribute('autoplay');
    }
  }

  get loop() {
    return this.#loop;
  }

  set loop(value) {
    if (value) {
      this.setAttribute('loop', '');
    } else {
      this.removeAttribute('loop');
    }
  }

  scrollToPrevious() {
    this.#embla.plugins().autoplay?.stop();
    this.#embla.scrollPrev();

    return this;
  }

  scrollTo(index) {
    this.#embla.plugins().autoplay?.stop();
    this.#embla.scrollTo(index);

    return this;
  }

  scrollToNext() {
    this.#embla.plugins().autoplay?.stop();
    this.#embla.scrollNext();

    return this;
  }

  play() {
    this.#embla.plugins().autoplay?.play();

    return this;
  }

  stop() {
    this.#embla.plugins().autoplay?.stop();

    return this;
  }

  #addAriaRoles() {
    if (!this.hasAttribute('role')) {
      this.setAttribute('role', 'group');
    }

    if (!this.hasAttribute('aria-roledescription')) {
      this.setAttribute('aria-roledescription', this.translate('role'));
    }
  }

  #enableAnnouncements() {
    toggleAriaLive(this.#viewport, true);
  }

  #disableAnnouncements() {
    toggleAriaLive(this.#viewport, false);
  }

  /** @param {import('embla-carousel').EmblaCarouselType} embla */
  #getState(embla) {
    return {
      selected: embla.selectedScrollSnap(),
      scrollSnapList: embla.scrollSnapList(),
      canScrollPrev: embla.canScrollPrev(),
      canScrollNext: embla.canScrollNext(),
      slides: embla.slideNodes(),
      slidesInView: embla.slidesInView(),
      slidesNotInView: embla.slidesNotInView()
    };
  }

  #update(props) {
    this.#updateItems(props);
    this.#updateNavigation(props);
    this.#updatePagination(props);
  }

  #updateEmbla() {
    this.#embla.reInit({
      slides: this.items,
      direction: this.dir,
      align: this.align,
      loop: this.loop
    }, [
      ...this.autoplay ? [emblaAutoplay({delay: this.autoplay})] : [],
    ]);
  }

  #updateStatus({slidesNotInView}) {
    this.toggleAttribute('idle', slidesNotInView.length === 0);
  }

  #updateItems({selected, slides, slidesInView}) {
    slides.forEach((element, index) => {
      const visible = slidesInView.includes(index);
      const current = index === selected;

      element.toggleAttribute('selected', current);
      element.toggleAttribute('inert', !current && !visible);
    });
  }

  #updateNavigation({canScrollPrev, canScrollNext}) {
    const prevButton = this.shadowRoot.querySelector('[part~="button-prev"]');
    const nextButton = this.shadowRoot.querySelector('[part~="button-next"]');

    prevButton.part.toggle('disabled', !canScrollPrev);
    nextButton.part.toggle('disabled', !canScrollNext);

    toggleAriaDisabled(prevButton, !canScrollPrev);
    toggleAriaDisabled(nextButton, !canScrollNext);
  }

  #updatePagination({selected, scrollSnapList}) {
    this.shadowRoot.querySelector('[part~="pagination"]').replaceChildren(
      ...scrollSnapList.map((_position, index) => {
        const label = document.createElement('span');
        const text = this.translate('pagination', {
          slide: index + 1,
          total: scrollSnapList.length
        });

        label.className = 'visually-hidden';
        label.textContent = text;

        const button = document.createElement('button');
        const current = index === selected;

        button.setAttribute('type', 'button');
        button.setAttribute('data-index', index);

        button.part.add('button', 'pagination-button');
        button.part.toggle('current', current);

        toggleAriaCurrent(button, current);
        toggleAriaDisabled(button, current);

        button.appendChild(label);

        return button;
      })
    );
  }

  #updateAutoplayButtons({autoplay, playing}) {
    const enabled = (autoplay === true) || (autoplay > 0);

    const playButton = this.shadowRoot.querySelector('[part~="button-play"]');
    const stopButton = this.shadowRoot.querySelector('[part~="button-stop"]');

    playButton.part.toggle('disabled', !enabled || playing);
    stopButton.part.toggle('disabled', !enabled || !playing);
  }

  async #scheduleEmblaUpdate() {
    if (!this.#emblaUpdateRequested) {
      this.#emblaUpdateRequested = true;
      this.#emblaUpdateRequested = await false;
      this.#updateEmbla();
    }
  }

  /** @param {MouseEvent} event */
  #handleClick = (event) => {
    const button = event.target.closest('button');

    if (button?.matches('[part~="pagination-button"]')) {
      this.scrollTo(parseInt(button.dataset.index || 0, 10));
      event.preventDefault();
    } else if (button?.matches('[part~="button-prev"]')) {
      this.scrollToPrevious();
      event.preventDefault();
    } else if (button?.matches('[part~="button-next"]')) {
      this.scrollToNext();
      event.preventDefault();
    } else if (button?.matches('[part~="button-stop"]')) {
      this.stop();
      event.preventDefault();
    } else if (button?.matches('[part~="button-play"]')) {
      this.play();
      event.preventDefault();
    }
  };

  /** @param {KeyboardEvent} event */
  #handleKeydown = (event) => {
    switch (event.key) {
      case 'ArrowLeft':
        this.scrollToPrevious();
        event.preventDefault();
        break;
      case 'ArrowRight':
        this.scrollToNext();
        event.preventDefault();
        break;
    }
  };

  /** @param {FocusEvent} _event */
  #handleFocusIn = (_event) => {
    this.#enableAnnouncements();
  };

  /** @param {FocusEvent} _event */
  #handleFocusOut = (_event) => {
    this.#disableAnnouncements();
  };

  /**  @param {Event} _event */
  #handleSlotChange = (_event) => {
    this.#scheduleEmblaUpdate();
  };

  /** @param {import('embla-carousel').EmblaCarouselType} embla */
  #handleEmblaReInit = (embla) => {
    this.#update(this.#getState(embla));
  };

  /** @param {import('embla-carousel').EmblaCarouselType} embla */
  #handleEmblaSelect = (embla) => {
    this.#update(this.#getState(embla));
  };

  /** @param {import('embla-carousel').EmblaCarouselType} embla */
  #handleEmblaSlidesInView = (embla) => {
    const props = this.#getState(embla);

    this.#updateStatus(props);
    this.#updateItems(props);
  };

  /** @param {import('embla-carousel').EmblaCarouselType} embla */
  #handleEmblaAutoplayStop = (_embla) => {
    this.#updateAutoplayButtons({
      autoplay: this.autoplay,
      playing: false
    });
  };

  /** @param {import('embla-carousel').EmblaCarouselType} embla */
  #handleEmblaAutoplayResume = (_embla) => {
    this.#updateAutoplayButtons({
      autoplay: this.autoplay,
      playing: true
    });
  };
}

export const LocalizeMixin = (superClass) => (
  class LocalizeMixin extends superClass {
    static get observedAttributes() {
      return ['lang'];
    }

    static get translations() {
      return {};
    }

    get documentLang() {
      return document.body.getAttribute('lang')
        || document.documentElement.getAttribute('lang')
        || window.navigator.language
        || 'en';
    }

    get lang() {
      return this.getAttribute('lang') || this.documentLang;
    }

    set lang(value) {
      if (value) {
        this.setAttribute('lang', value);
      } else {
        this.removeAttribute('lang');
      }
    }

    connectedCallback() {
      this.localize(this.lang);
    }

    attributeChangedCallback(name, oldValue, newValue) {
      if (name === 'lang') {
        this.localize(newValue);
      }
    }

    localize(lang) {
      this.shadowRoot.querySelectorAll('[data-localize]').forEach((element) => {
        const key = element.getAttribute('data-localize');

        if (!key) {
          return;
        }

        element.textContent = this.translate(key, {}, lang);
      });
    }

    translate(key, replace, lang) {
      lang ??= this.documentLang;

      const [language] = lang.split('-');

      let translation = this.constructor.translations?.[lang]?.[key]
        || this.constructor.translations?.[language]?.[key];

      if (!translation) {
        throw new Error(`No translation available: ${key}`);
      }

      for (const [placeholder, value] of Object.entries(replace || {})) {
        translation = translation.replace(`{${placeholder}}`, value);
      }

      return translation;
    }
  }
);

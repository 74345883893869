/**
 * Determine whether the user prefers a reduced set of animations.
 *
 * @returns {boolean}
 */
export function prefersReducedMotion() {
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
  return mediaQuery.matches;
}

/**
 * Wait for the next browser repaint.
 *
 * @returns {Promise}
 */
export function nextFrame() {
  return new Promise((resolve) => {
    // Workaround: A chome bug sometimes incorrectly runs rAF in the current
    // frame {@link https://bugs.chromium.org/p/chromium/issues/detail?id=675795}.
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve);
    });
  });
}

/**
 * Animate a given element.
 *
 * @param {Element} element The element to animate.
 * @param {object|object[]} keyframes The animation keyframes.
 * @param {object} options The animation options.
 * @returns {Promise}
 */
export function animate(element, keyframes, options = {}) {
  return new Promise((resolve) => {
    const duration = options.duration ?? 3000;

    const animation = element.animate(keyframes, {
      ...options,
      duration: prefersReducedMotion() ? 0 : duration,
    });

    const handler = () => {
      animation.removeEventListener('finish', handler);
      animation.removeEventListener('cancel', handler);
      resolve(animation);
    };

    animation.addEventListener('finish', handler);
    animation.addEventListener('cancel', handler);
  });
}

/**
 * Stop all animations on a given element.
 *
 * @param {Element} element The element to stop.
 * @returns {Promise}
 */
export function stopAnimations(element) {
  return Promise.all(
    element.getAnimations().map((animation) => (
      new Promise((resolve) => {
        const handler = () => {
          animation.removeEventListener('finish', handler);
          animation.removeEventListener('cancel', handler);
          resolve(animation);
        };

        animation.addEventListener('finish', handler);
        animation.addEventListener('cancel', handler);

        animation.cancel();
      })
    ))
  );
}

/**
 * Determine whether an element has a CSS animation applied.
 *
 * @param {Element} element The element to inspect.
 * @returns {boolean}
 */
export function hasAnimationInfo(element) {
  const {timeout} = getAnimationInfo(element);

  if (timeout === 0) {
    return false;
  }

  return true;
}

/**
 * Collect information about CSS animations on the given element.
 *
 * @param {Element} element The element to inspect.
 * @returns {{timeout: number, properties: number}}
 */
export function getAnimationInfo(element) {
  const styles = window.getComputedStyle(element);

  const durations = (styles.animationDuration || '0s').split(', ').map(parseDuration);
  const delays = (styles.animationDelay || '0s').split(', ').map(parseDuration);

  const animations = Math.max(durations.length, delays.length);

  const timeout = Math.max(
    ...durations.map((duration, index) => duration + delays[index % delays.length])
  );

  return {
    timeout,
    animations
  };
}

/**
 * Wait for the end of a CSS animation.
 *
 * @param {Element} element The element to inspect.
 * @returns {Promise<Element>}
 */
export async function whenAnimationEnds(element) {
  const {timeout, animations} = getAnimationInfo(element);

  if (timeout === 0) {
    return element;
  }

  return whenFinished('animation', element, timeout, animations);
}

/**
 * Determine whether an element has a CSS transition applied.
 *
 * @param {Element} element The element to inspect.
 * @returns {boolean}
 */
export function hasTransitionInfo(element) {
  const {timeout} = getTransitionInfo(element);

  if (timeout === 0) {
    return false;
  }

  return true;
}

/**
 * Collect information about CSS transitions on the given element.
 *
 * @param {Element} element The element to inspect.
 * @returns {{timeout: number, properties: number}}
 */
export function getTransitionInfo(element) {
  const styles = window.getComputedStyle(element);

  const durations = (styles.transitionDuration || '0s').split(', ').map(parseDuration);
  const delays = (styles.transitionDelay || '0s').split(', ').map(parseDuration);

  const properties = Math.max(durations.length, delays.length);

  const timeout = Math.max(
    ...durations.map((duration, index) => duration + delays[index % delays.length])
  );

  return {
    timeout,
    properties
  };
}

/**
 * Wait for the end of a CSS transition.
 *
 * @param {Element} element The element to inspect.
 * @returns {Promise<Element>}
 */
export async function whenTransitionEnds(element) {
  const {timeout, properties} = getTransitionInfo(element);

  if (timeout === 0) {
    return element;
  }

  return whenFinished('transition', element, timeout, properties);
}

/**
 * Parse a CSS duration.
 *
 * @param {string|number} value The value to parse.
 * @returns {number}
 */
export function parseDuration(value) {
  value = value.toString().trim().toLowerCase();

  if (value.includes('ms')) {
    return parseFloat(value);
  }

  if (value.includes('s')) {
    return parseFloat(value) * 1000;
  }

  return parseFloat(value);
}

/**
 * Wait for a CSS animation/transition to finish.
 *
 * @param {string} type The animation type.
 * @param {string} element The element to inspect.
 * @param {number} timeout The maximum duration to wait.
 * @param {number} limit The total number of transitions/animations applied to the element.
 * @returns {Promise<Element>}
 */
export function whenFinished(type, element, timeout, limit = 1) {
  return new Promise((resolve) => {
    let counter = 0;

    const settle = () => {
      element.removeEventListener(`${type}end`, onEnd);
      element.removeEventListener(`${type}cancel`, onCancel);

      clearTimeout(timer);
      resolve(element);
    };

    const onEnd = (event) => {
      if (event.target === element) {
        if (++counter >= limit) {
          settle();
        }
      }
    };

    const onCancel = (event) => {
      if (event.target === element) {
        settle();
      }
    };

    const timer = setTimeout(() => {
      if (counter < limit) {
        settle();
      }
    }, timeout + 1);

    element.addEventListener(`${type}end`, onEnd);
    element.addEventListener(`${type}cancel`, onCancel);
  });
}

/**
 * Toggle the `aria-live` attribute on an element.
 *
 * @param {Element} element The element to modify.
 * @param {?boolean} [force] If false the attribute will only be removed. If true the attribute will only be added.
 * @returns {boolean}
 */
export function toggleAriaLive(element, force) {
  const state = force ?? !element.hasAttribute('aria-live');

  if (state) {
    element.setAttribute('aria-live', 'polite');
  } else {
    element.removeAttribute('aria-live');
  }

  return state;
}

/**
 * Toggle the `aria-current` attribute on an element.
 *
 * @param {Element} element The element to modify.
 * @param {?boolean} [force] If false the attribute will only be removed. If true the attribute will only be added.
 * @returns {boolean}
 */
export function toggleAriaCurrent(element, force) {
  const state = force ?? !element.hasAttribute('aria-current');

  if (state) {
    element.setAttribute('aria-current', 'true');
  } else {
    element.removeAttribute('aria-current');
  }

  return state;
}

/**
 * Toggle the `aria-disabled` attribute on an element.
 *
 * @param {Element} element The element to modify.
 * @param {?boolean} [force] If false the attribute will only be removed. If true the attribute will only be added.
 * @returns {boolean}
 */
export function toggleAriaDisabled(element, force) {
  const state = force ?? !element.hasAttribute('aria-disabled');

  if (state) {
    element.setAttribute('aria-disabled', 'true');
  } else {
    element.removeAttribute('aria-disabled');
  }

  return state;
}

/**
 * Wrap a given element in a new container element.
 *
 * @param {Element} element The element to wrap.
 * @param {Element} container The structure to wrap around the element.
 * @returns {Element}
 */
export function wrap(element, container) {
  element.parentNode.insertBefore(container, element);
  container.appendChild(element);
  return container;
}

/**
 * Wrap a new container element around all elements in the collection.
 *
 * @param {Element[]|NodeList} elements The elements to wrap.
 * @param {Element} container The structure to wrap around the element.
 * @returns {Element}
 */
export function wrapAll(elements, container) {
  const parent = elements[0]?.parentNode;
  const nextSibling = elements[0]?.nextSibling;

  for (let i = 0; i < elements.length; i++) {
    container.appendChild(elements[i]);
  }

  parent?.insertBefore(container, nextSibling);

  return container;
}

/**
 * Wrap the children of a given element in a new container.
 *
 * @param {Element} element The element to manipulate.
 * @param {Element} container The structure to wrap around the children.
 * @returns {Element}
 */
export function wrapInner(element, container) {
  if (element.hasChildNodes()) {
    wrapAll(element.childNodes, container);
  } else {
    element.appendChild(container);
  }

  return container;
}

/**
 * Unwrap a given element.
 *
 * @param {Element} element The element to unwrap.
 * @returns {Element}
 */
export function unwrap(element) {
  const container = element.parentNode;

  while (element.firstChild) {
    container.insertBefore(element.firstChild, element);
  }

  container.removeChild(element);

  return container;
}

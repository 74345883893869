import {
  prefersReducedMotion,
  nextFrame,
  hasTransitionInfo,
  whenTransitionEnds
} from '@/utilities/animation.js';

const template = document.createElement('template');

template.innerHTML = /*html*/`
  <style>
    :host {
      display: block;
    }

    ::slotted(button) {
      overflow: visible;
      width: 100%;
      padding: 0;
      margin: 0;
      border: 0;
      font: inherit;
      background: transparent;
    }
  </style>
  <slot name="toggle"></slot>
  <slot name="panel"></slot>
`;

export class Disclosure extends HTMLElement {
  static get observedAttributes() {
    return ['open'];
  }

  #button = null;
  #panel = null;

  #mediaQueries = {};
  #previousStates = {};

  constructor() {
    super();

    const shadowRoot = this.attachShadow({mode: 'open'});
    shadowRoot.append(template.content.cloneNode(true));
  }

  connectedCallback() {
    this.#button = this.querySelector('[slot="toggle"]');
    this.#panel = this.querySelector('[slot="panel"]');

    this.#mediaQueries = this.#generateMediaQueries([
      'force-open',
      'force-close',
      'close-on-click-outside',
      'close-on-escape',
    ]);

    if (this.#mediaQueries.forceOpen?.matches) {
      this.#setState({open: true});
    } else if (this.#mediaQueries.forceClose?.matches) {
      this.#setState({open: false});
    } else {
      this.#setState({open: this.open});
    }

    document.addEventListener('click', this.#handleClick);
    this.addEventListener('keydown', this.#handleKeydown);

    this.#mediaQueries.forceOpen?.addEventListener('change', this.#handleForceOpenQueryChange);
    this.#mediaQueries.forceClose?.addEventListener('change', this.#handleForceCloseQueryChange);
  }

  disconnectedCallback() {
    document.removeEventListener('click', this.#handleClick);
    this.removeEventListener('keydown', this.#handleKeydown);

    this.#mediaQueries.forceOpen?.removeEventListener('change', this.#handleForceOpenQueryChange);
    this.#mediaQueries.forceClose?.removeEventListener('change', this.#handleForceCloseQueryChange);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === 'open') {
      if (!!newValue !== !!oldValue) {
        this.toggle(!!newValue);
      }
    }
  }

  get open() {
    return this.hasAttribute('open');
  }

  set open(value) {
    if (value) {
      this.setAttribute('open', '');
    } else {
      this.removeAttribute('open');
    }
  }

  get animate() {
    return this.hasAttribute('animate');
  }

  set animate(value) {
    if (value) {
      this.setAttribute('animate', '');
    } else {
      this.removeAttribute('animate');
    }
  }

  expand() {
    return this.toggle(true);
  }

  collapse() {
    return this.toggle(false);
  }

  toggle(force) {
    const open = force ?? !this.open;

    if (this.#useTransition()) {
      this.#transitionState({open});
    } else {
      this.#setState({open});
    }

    return this;
  }

  #generateMediaQueries(attributes) {
    return attributes
      .filter((attribute) => this.hasAttribute(attribute))
      .reduce((mediaQueries, attribute) => {
        const name = attribute.replace(/-./g, (str) => str.charAt(1).toUpperCase());
        const query = this.getAttribute(attribute) || 'all';

        mediaQueries[name] = window.matchMedia(query);
        return mediaQueries;
      }, {});
  }

  #useTransition() {
    return !prefersReducedMotion() && (this.animate || hasTransitionInfo(this.#panel));
  }

  #setState({open}) {
    const className = this.getAttribute('toggle-document-class');

    this.toggleAttribute('open', open);

    this.#panel.toggleAttribute('hidden', !open);
    this.#button.setAttribute('aria-expanded', !open ? 'false' : 'true');

    if (className) {
      document.documentElement.classList.toggle(className, open);
    }
  }

  async #transitionState({open}) {
    const transition = open ? 'opening' : 'closing';

    if (open) {
      this.#setState({open});
    }

    this.#panel.classList.add(transition);
    this.#panel.classList.add(`${transition}-from`);

    await nextFrame();

    this.#panel.classList.remove(`${transition}-from`);
    this.#panel.classList.add(`${transition}-to`);

    await whenTransitionEnds(this.#panel);

    this.#panel.classList.remove(`${transition}-to`);
    this.#panel.classList.remove(transition);

    if (!open) {
      this.#setState({open});
    }
  }

  /** @param {MouseEvent} event */
  #handleClick = (event) => {
    const buttonClicked = this.#button.contains(event.target);
    const panelClicked = this.#panel.contains(event.target);

    const clickedOutside = !buttonClicked && !panelClicked;
    const closeOnClickOutside = this.#mediaQueries.closeOnClickOutside?.matches;

    if (buttonClicked) {
      this.toggle();
      event.preventDefault();
    } else if (clickedOutside && closeOnClickOutside && this.open) {
      this.collapse();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  /**
   * @param {KeyboardEvent} event
   */
  #handleKeydown = (event) => {
    const {key} = event;

    if (key !== 'Escape') {
      return;
    }

    const closeOnEscape = this.#mediaQueries.closeOnEscape?.matches;

    if (!this.open || !closeOnEscape) {
      return;
    }

    this.collapse();
    this.#button.focus();

    event.stopPropagation();
    event.preventDefault();
  };

  /** @param {MediaQueryListEvent} event */
  #handleForceOpenQueryChange = (event) => {
    if (event.matches) {
      this.#previousStates.forceOpen = this.open;
      this.#setState({open: true});
    } else if (!this.#previousStates?.forceOpen) {
      this.#setState({open: false});
    }
  };

  /** @param {MediaQueryListEvent} event */
  #handleForceCloseQueryChange = (event) => {
    if (event.matches) {
      this.#previousStates.forceClose = this.open;
      this.#setState({open: false});
    } else if (this.#previousStates?.forceClose) {
      this.#setState({open: true});
    }
  };
}
